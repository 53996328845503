@font-face {
  font-family: 'LineSeedJP';
  src: url(/assets/font/LINESeedJP_OTF_Rg.woff2) format('woff2');
	font-display: swap;
}

@font-face {
  font-family: 'LineSeedJP';
  src: url(/assets/font/LINESeedJP_OTF_Bd.woff2) format('woff2');
	font-weight: bold;
	font-display: swap;
}

div {
  font-family: LineSeedJP;
}